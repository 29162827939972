/*
 * Custom code goes here.
 * A template should always ship with an empty custom.js
 */
import Swiper from 'swiper';
import SwiperCore, { Pagination } from 'swiper/core';
SwiperCore.use([Pagination]);
import 'swiper/swiper-bundle.css';
import 'svg-classlist-polyfill'

const swiper = new Swiper('.swiper-container', {
  loop: true,
  pagination: {
    el: '.swiper-pagination',
  },
});

let headerLogo = document.querySelector('.header_logo');
let headerLogoMobile = document.querySelector('.header_mobile_logo');

window.addEventListener('scroll', function() {
  let scroll = window.pageYOffset;
  if (scroll > 0) {
    headerLogo.classList.add('header_logo_collapse');

    headerLogoMobile.classList.add('header_logo_mobile_collapse');

  } else {
    headerLogo.classList.remove('header_logo_collapse');

    headerLogoMobile.classList.remove('header_logo_mobile_collapse');
  }
});